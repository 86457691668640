/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #F1FAEE;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
}

h1, h2 {
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  margin-top: 20px;
}

.navbar {
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
  padding: 12px;
  text-decoration-line: none;
}

.navbar .link {
  text-decoration-line: none;
  margin-left: 20px;
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.navbar .link:hover {
  /* background-color: #1D3557; */
  color: white;
}

.camera {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  max-width: 100vw;
  margin: 5px 0;
}

button {
  background-color: rgba(255, 255, 230, 0.8);
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

button:hover {
  color: white;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white;
  text-align: center;
}

.footer p {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: black;
  margin: 0px;
}

.flexbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.stickflex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stickman-container {
  margin-bottom: 5px;
}

.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}


.camerabutton {
  margin: 10px;
}

.camerabutton:hover {
  cursor: pointer;
}

.camerabutton:disabled {
  font-size: 3rem;
  color: black;
}

.app-container {
  display: flex;
  flex-direction: column;
}

.outputimagescontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: center;
  padding: 20px;
  /* border:#264653 solid; */
  border-radius: 10px;
  background-color: rgba(151, 151, 151, 0.508);
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-left: 10vw;
  margin-right: 10vw;
}

.outputimagescontainer span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.outputimagescontainer span h2 {
  margin: 0;
}

button:hover {
  cursor: pointer;
}

input:hover {
  cursor: pointer;
}

map:hover {
  cursor: pointer;
}

select {
  background-color: rgba(255, 255, 230, 0.8);
  margin-bottom: 10px;
}

option {
  background-color: rgba(255, 255, 230, 0.8);
}

.outputimageitself {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
} 

.outputimage {
  width: 25vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-content: center;
}

.content {
  min-height: calc(100vh - 69px);
  background: url('pages/kmskb_blur.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.flexbox-container-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

input[type="file"] {
  display: none;
}

input[type="ckeckbox"] {
  width: 20px;
  height: 20px;
}

.infobox {
  background-color: rgba(255, 255, 230, 0.508);
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

.infobox p {
  padding: 0.5rem;
  max-width: 50vw;
}

.custom-file-upload {
  background-color: rgba(255, 255, 230, 0.8);
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 2rem;
}

.custom-file-upload:hover {
  cursor: pointer;
  /* background-color: #264653; */
  color: white;
}

.label {
  cursor: pointer;
  border: 1px solid #264653;
  border-radius: 5px;
  display: inline-block;
}

.imageToChooseFrom {
  max-width: 50vw;
  max-height: 30vh;
}

area {
  border: blue;
}

.rotatebutton {
  color: black;
  font-size: 2.5rem;
  padding: 0.5rem 0.8rem;
  margin: 0.5rem;
}

a {
  margin: 0px;
  padding: 0px;
}

.rotatebutton:hover {
  color: white;
}

.loadmore {
  background-color: rgba(151, 151, 151, 0.508);
  border-radius: 1rem;
  margin: auto;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

table {
  background-color: rgba(255, 255, 230, 0.508);
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

table td {
  padding: 0.3rem 2rem;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 230, 0.65);
}

.langselect {
  display: flex;
}

.langbutton {
  background-color: rgba(255, 255, 255, 0);
  text-decoration: underline;
  padding: 1rem;
}

.langbutton:hover {
  cursor: pointer;
}

pre {
  font-family: Arial, Helvetica, sans-serif;
  white-space: pre-wrap;
}

.homesection {
  background-color: rgba(255, 255, 230, 0.7);
  border-radius: 1rem;
  padding: 1rem;
  width: min(max(70vw, 900px), 90vw);
  margin: auto;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.homesection h2 {
  padding: 1rem;
  margin: 0;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.homepage h1 {
  text-decoration: underline;
}

ul li {
  margin-left: 2rem;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.homesection p {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  #camera, #stickman {
    display: none;
  }

  .footer p {
    display: none;
  }

  .table {
    overflow-x: auto;
    width: 90vw;
  }
}